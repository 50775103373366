import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../providers/survey.service';
import * as Survey from 'survey-angular';

@Component({
  selector: 'app-survey-response',
  templateUrl: './survey-response.component.html',
  providers: [SurveyService]
})
export class SurveyResponseComponent implements OnInit {

  requestId: any;
  loader: boolean;

  constructor(
		private route: ActivatedRoute,
	    private survey: SurveyService) { }

  ngOnInit() {
		this.requestId = this.route.snapshot.queryParams['requestId']
		this.requestDetail(this.requestId)
  }
  /**
   * To show th response of user to the feedback campaign.
   * Open this URL only if the seviceType is FEEDBACK (check this in native app)
   * @param id
   * @param idx
   */
  requestDetail(requestId: any) {
      this.loader = true;
      this.survey.getRequestsDetailsById(requestId).subscribe(
        data => {
            if(data.hasOwnProperty('campaignId'))
              this.setSurveyResponse(data.campaignId, data.campaignResponseContent, true)
            else if(data.hasOwnProperty('formId'))
              this.setSurveyResponse(data.formId, data.formResponse, false)
            else
              this.survey.showToast('error', '', 'Invalid Request (Campaign Id not found!)');
        },
        error => {
        	this.survey.showToast('error', '', error);
        }
      );
  }
  setSurveyResponse(surveyId: any, campaignResponseContent: any, isCampaignSurvey){
    if(isCampaignSurvey){
      this.survey
      .getFeedbackCampaignById(surveyId)
      .subscribe(
          data => {
            this.loader = false;
            // console.log(campaignResponseContent)
            var surveyJSON = data.setting.question.content
            surveyJSON = this.filterSurveyJSON(surveyJSON, campaignResponseContent);
            const surveyModel = new Survey.Model(surveyJSON);
            surveyModel.data = JSON.parse(campaignResponseContent)
            surveyModel.mode = 'display';
            Survey.SurveyNG.render('surveyElement', { model: surveyModel });
          },
          error => {
            this.survey.showToast('error', '', error);
          }
        );
    }else{
      this.survey.getFormDetailsById(surveyId).subscribe(
        data => {
          var surveyJSON = data.questionnaire.content
          surveyJSON = this.filterSurveyJSON(surveyJSON, campaignResponseContent);
          const surveyModel = new Survey.Model(surveyJSON);
          surveyModel.data = JSON.parse(campaignResponseContent)
          surveyModel.mode = 'display';
          Survey.SurveyNG.render('surveyElement', { model: surveyModel });
        },
        error => {
          this.survey.showToast('error', '', error);
        }
      );
    }


  }

  filterSurveyJSON(surveyJSON: any, campaignResponseContent: any){
  	var surveyJSONPages = JSON.parse(surveyJSON).pages
  	var campaignResponseContent = JSON.parse(campaignResponseContent)

  	for(var page of surveyJSONPages){
  		for(var element of page.elements){
  			if(element.hasOwnProperty('choices')){
  				var values = campaignResponseContent[element.name]
  				var newChoices : any = [];
  				if(values.constructor === Array){ //checking if array or not
	  				for(var value of values){
	  					for(var choice of element.choices)
	  						if(choice.value == value)
	  							newChoices.push(choice)
	  				}
  				}else{
	  				for(var choice of element.choices)
	  					if(choice.value == values)
	  						newChoices.push(choice)
  				}
  				element.choices = newChoices
  			}
  		}
  	}
  	
  	surveyJSON = JSON.parse(surveyJSON)
  	surveyJSON.pages = surveyJSONPages
  	return surveyJSON;
  }
}
