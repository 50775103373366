// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: false,

  BASE_URL : 'https://www.quseapp.com', // url based on the domain.
  api_url : 'https://jwt.quseapp.com/',

  googleKey : 'AIzaSyB2xPzFqzoMmrmfUN0qavEGfktXHhN5TK0',

  cc : '',
  ph : '',

  youtubeVideoID: 'ZeA9L5DoQWw',

  // footer links
  refer_business : "/addnote?id=5afa6524995b940001c3e1db&sid=5afa65a4995b940001c3e1de",
  register_business : "https://business.quseapp.com",
  schedule_demo : "/addnote?id=5afa6524995b940001c3e1db&sid=5afa65a4995b940001c3e1dd",
  contact_us : "/addnote?id=5afa6524995b940001c3e1db",
  socialMedia_Fb : "https://www.facebook.com/Quseapp-466265460243475/",
  socialMedia_twitter : "https://twitter.com/quseapp",
  socialMedia_insta : "https://www.instagram.com/quse.app/?hl=en",
  // footer links
  app_Gplay : "https://play.google.com/store/apps/details?id=com.consumer.quse",
  app_ios : "https://itunes.apple.com/us/app/quse/id1082142564?ls=1&mt=8",


  flag: [
  { code: '376', flag: 'ad', name: 'Andorra' },
  { code: '971', flag: 'ae', name: 'United arab emirates' },
  { code: '93', flag: 'af', name: 'Afghanistan' },
  { code: '1268', flag: 'ag', name: 'Antigua and barbuda' },
  { code: '1264', flag: 'ai', name: 'Anguilla' },
  { code: '355', flag: 'al', name: 'Albania' },
  { code: '374', flag: 'am', name: 'Armenia' },
  { code: '244', flag: 'ao', name: 'Angola' },
  { code: '672', flag: 'aq', name: 'Antarctica' },
  { code: '54', flag: 'ar', name: 'Argentina' },
  { code: '1684', flag: 'as', name: 'American samoa' },
  { code: '43', flag: 'at', name: 'Austria' },
  { code: '61', flag: 'au', name: 'Australia' },
  { code: '297', flag: 'aw', name: 'Aruba' },
  { code: '994', flag: 'az', name: 'Azerbaijan' },
  { code: '387', flag: 'ba', name: 'Bosnia and herzegovina' },
  { code: '1246', flag: 'bb', name: 'Barbados' },
  { code: '880', flag: 'bd', name: 'Bangladesh' },
  { code: '32', flag: 'be', name: 'Belgium' },
  { code: '226', flag: 'bf', name: 'Burkina faso' },
  { code: '359', flag: 'bg', name: 'Bulgaria' },
  { code: '973', flag: 'bh', name: 'Bahrain' },
  { code: '257', flag: 'bi', name: 'Burundi' },
  { code: '229', flag: 'bj', name: 'Benin' },
  { code: '590', flag: 'bl', name: 'Saint barthelemy' },
  { code: '1441', flag: 'bm', name: 'Bermuda' },
  { code: '673', flag: 'bn', name: 'Brunei darussalam' },
  { code: '591', flag: 'bo', name: 'Bolivia' },
  { code: '55', flag: 'br', name: 'Brazil' },
  { code: '1242', flag: 'bs', name: 'Bahamas' },
  { code: '975', flag: 'bt', name: 'Bhutan' },
  { code: '267', flag: 'bw', name: 'Botswana' },
  { code: '375', flag: 'by', name: 'Belarus' },
  { code: '501', flag: 'bz', name: 'Belize' },
  { code: '1', flag: 'ca', name: 'Canada' },
  { code: '61', flag: 'cc', name: 'Cocos (keeling) islands' },
  { code: '243', flag: 'cd', name: 'Congo' },
  { code: '236', flag: 'cf', name: 'Central african republic' },
  { code: '242', flag: 'cg', name: 'Congo' },
  { code: '41', flag: 'ch', name: 'Switzerland' },
  { code: '225', flag: 'ci', name: 'Cote d ivoire' },
  { code: '682', flag: 'ck', name: 'Cook islands' },
  { code: '56', flag: 'cl', name: 'Chile' },
  { code: '237', flag: 'cm', name: 'Cameroon' },
  { code: '86', flag: 'cn', name: 'China' },
  { code: '57', flag: 'co', name: 'Colombia' },
  { code: '506', flag: 'cr', name: 'Costa rica' },
  { code: '53', flag: 'cu', name: 'Cuba' },
  { code: '238', flag: 'cv', name: 'Cape verde' },
  { code: '61', flag: 'cx', name: 'Christmas island' },
  { code: '357', flag: 'cy', name: 'Cyprus' },
  { code: '420', flag: 'cz', name: 'Czech republic' },
  { code: '49', flag: 'de', name: 'Germany' },
  { code: '253', flag: 'dj', name: 'Djibouti' },
  { code: '45', flag: 'dk', name: 'Denmark' },
  { code: '1767', flag: 'dm', name: 'Dominica' },
  { code: '1809', flag: 'do', name: 'Dominican republic' },
  { code: '213', flag: 'dz', name: 'Algeria' },
  { code: '593', flag: 'ec', name: 'Ecuador' },
  { code: '372', flag: 'ee', name: 'Estonia' },
  { code: '20', flag: 'eg', name: 'Egypt' },
  { code: '291', flag: 'er', name: 'Eritrea' },
  { code: '34', flag: 'es', name: 'Spain' },
  { code: '251', flag: 'et', name: 'Ethiopia' },
  { code: '358', flag: 'fi', name: 'Finland' },
  { code: '679', flag: 'fj', name: 'Fiji' },
  { code: '500', flag: 'fk', name: 'Falkland islands (malvinas)' },
  { code: '691', flag: 'fm', name: 'Micronesia' },
  { code: '298', flag: 'fo', name: 'Faroe islands' },
  { code: '33', flag: 'fr', name: 'France' },
  { code: '241', flag: 'ga', name: 'Gabon' },
  { code: '44', flag: 'gb', name: 'United kingdom' },
  { code: '1473', flag: 'gd', name: 'Grenada' },
  { code: '995', flag: 'ge', name: 'Georgia' },
  { code: '233', flag: 'gh', name: 'Ghana' },
  { code: '350', flag: 'gi', name: 'Gibraltar' },
  { code: '299', flag: 'gl', name: 'Greenland' },
  { code: '220', flag: 'gm', name: 'Gambia' },
  { code: '224', flag: 'gn', name: 'Guinea' },
  { code: '240', flag: 'gq', name: 'Equatorial guinea' },
  { code: '30', flag: 'gr', name: 'Greece' },
  { code: '502', flag: 'gt', name: 'Guatemala' },
  { code: '1671', flag: 'gu', name: 'Guam' },
  { code: '245', flag: 'gw', name: 'Guinea-bissau' },
  { code: '592', flag: 'gy', name: 'Guyana' },
  { code: '852', flag: 'hk', name: 'Hong kong' },
  { code: '504', flag: 'hn', name: 'Honduras' },
  { code: '385', flag: 'hr', name: 'Croatia' },
  { code: '509', flag: 'ht', name: 'Haiti' },
  { code: '36', flag: 'hu', name: 'Hungary' },
  { code: '62', flag: 'id', name: 'Indonesia' },
  { code: '353', flag: 'ie', name: 'Ireland' },
  { code: '972', flag: 'il', name: 'Israel' },
  { code: '44', flag: 'im', name: 'Isle of man' },
  { code: '91', flag: 'in', name: 'India' },
  { code: '964', flag: 'iq', name: 'Iraq' },
  { code: '98', flag: 'ir', name: 'Iran' },
  { code: '354', flag: 'is', name: 'Iceland' },
  { code: '39', flag: 'it', name: 'Italy' },
  { code: '1876', flag: 'jm', name: 'Jamaica' },
  { code: '962', flag: 'jo', name: 'Jordan' },
  { code: '81', flag: 'jp', name: 'Japan' },
  { code: '254', flag: 'ke', name: 'Kenya' },
  { code: '996', flag: 'kg', name: 'Kyrgyzstan' },
  { code: '855', flag: 'kh', name: 'Cambodia' },
  { code: '686', flag: 'ki', name: 'Kiribati' },
  { code: '269', flag: 'km', name: 'Comoros' },
  { code: '1869', flag: 'kn', name: 'Saint kitts and nevis' },
  { code: '850', flag: 'kp', name: 'Korea north' },
  { code: '82', flag: 'kr', name: 'Korea south' },
  { code: '965', flag: 'kw', name: 'Kuwait' },
  { code: '1345', flag: 'ky', name: 'Cayman islands' },
  { code: '7', flag: 'kz', name: 'Kazakstan' },
  { code: '856', flag: 'la', name: 'Laos' },
  { code: '961', flag: 'lb', name: 'Lebanon' },
  { code: '1758', flag: 'lc', name: 'Saint lucia' },
  { code: '423', flag: 'li', name: 'Liechtenstein' },
  { code: '94', flag: 'lk', name: 'Sri lanka' },
  { code: '231', flag: 'lr', name: 'Liberia' },
  { code: '266', flag: 'ls', name: 'Lesotho' },
  { code: '370', flag: 'lt', name: 'Lithuania' },
  { code: '352', flag: 'lu', name: 'Luxembourg' },
  { code: '371', flag: 'lv', name: 'Latvia' },
  { code: '218', flag: 'ly', name: 'Libyan arab jamahiriya' },
  { code: '212', flag: 'ma', name: 'Morocco' },
  { code: '377', flag: 'mc', name: 'Monaco' },
  { code: '373', flag: 'md', name: 'Moldova, republic of' },
  { code: '382', flag: 'me', name: 'Montenegro' },
  { code: '1599', flag: 'mf', name: 'Saint martin' },
  { code: '261', flag: 'mg', name: 'Madagascar' },
  { code: '692', flag: 'mh', name: 'Marshall islands' },
  { code: '389', flag: 'mk', name: 'Macedonia' },
  { code: '223', flag: 'ml', name: 'Mali' },
  { code: '95', flag: 'mm', name: 'Myanmar' },
  { code: '976', flag: 'mn', name: 'Mongolia' },
  { code: '853', flag: 'mo', name: 'Macau' },
  { code: '1670', flag: 'mp', name: 'Northern mariana islands' },
  { code: '222', flag: 'mr', name: 'Mauritania' },
  { code: '1664', flag: 'ms', name: 'Montserrat' },
  { code: '356', flag: 'mt', name: 'Malta' },
  { code: '230', flag: 'mu', name: 'Mauritius' },
  { code: '960', flag: 'mv', name: 'Maldives' },
  { code: '265', flag: 'mw', name: 'Malawi' },
  { code: '52', flag: 'mx', name: 'Mexico' },
  { code: '60', flag: 'my', name: 'Malaysia' },
  { code: '258', flag: 'mz', name: 'Mozambique' },
  { code: '264', flag: 'na', name: 'Namibia' },
  { code: '687', flag: 'nc', name: 'New caledonia' },
  { code: '227', flag: 'ne', name: 'Niger' },
  { code: '234', flag: 'ng', name: 'Nigeria' },
  { code: '505', flag: 'ni', name: 'Nicaragua' },
  { code: '31', flag: 'nl', name: 'Netherlands' },
  { code: '47', flag: 'no', name: 'Norway' },
  { code: '977', flag: 'np', name: 'Nepal' },
  { code: '674', flag: 'nr', name: 'Nauru' },
  { code: '683', flag: 'nu', name: 'Niue' },
  { code: '64', flag: 'nz', name: 'New zealand' },
  { code: '968', flag: 'om', name: 'Oman' },
  { code: '507', flag: 'pa', name: 'Panama' },
  { code: '51', flag: 'pe', name: 'Peru' },
  { code: '689', flag: 'pf', name: 'French polynesia' },
  { code: '675', flag: 'pg', name: 'Papua new guinea' },
  { code: '63', flag: 'ph', name: 'Philippines' },
  { code: '92', flag: 'pk', name: 'Pakistan' },
  { code: '48', flag: 'pl', name: 'Poland' },
  { code: '508', flag: 'pm', name: 'Saint pierre and miquelon' },
  { code: '870', flag: 'pn', name: 'Pitcairn' },
  { code: '1', flag: 'pr', name: 'Puerto rico' },
  { code: '351', flag: 'pt', name: 'Portugal' },
  { code: '680', flag: 'pw', name: 'Palau' },
  { code: '595', flag: 'py', name: 'Paraguay' },
  { code: '974', flag: 'qa', name: 'Qatar' },
  { code: '40', flag: 'ro', name: 'Romania' },
  { code: '381', flag: 'rs', name: 'Serbia' },
  { code: '7', flag: 'ru', name: 'Russian federation' },
  { code: '250', flag: 'rw', name: 'Rwanda' },
  { code: '966', flag: 'sa', name: 'Saudi arabia' },
  { code: '677', flag: 'sb', name: 'Solomon islands' },
  { code: '248', flag: 'sc', name: 'Seychelles' },
  { code: '249', flag: 'sd', name: 'Sudan' },
  { code: '46', flag: 'se', name: 'Sweden' },
  { code: '65', flag: 'sg', name: 'Singapore' },
  { code: '290', flag: 'sh', name: 'Saint helena' },
  { code: '386', flag: 'si', name: 'Slovenia' },
  { code: '421', flag: 'sk', name: 'Slovakia' },
  { code: '232', flag: 'sl', name: 'Sierra leone' },
  { code: '378', flag: 'sm', name: 'San marino' },
  { code: '221', flag: 'sn', name: 'Senegal' },
  { code: '252', flag: 'so', name: 'Somalia' },
  { code: '597', flag: 'sr', name: 'Suriname' },
  { code: '239', flag: 'st', name: 'Sao tome and principe' },
  { code: '503', flag: 'sv', name: 'El salvador' },
  { code: '963', flag: 'sy', name: 'Syrian arab republic' },
  { code: '268', flag: 'sz', name: 'Swaziland' },
  { code: '1649', flag: 'tc', name: 'Turks and caicos islands' },
  { code: '235', flag: 'td', name: 'Chad' },
  { code: '228', flag: 'tg', name: 'Togo' },
  { code: '66', flag: 'th', name: 'Thailand' },
  { code: '992', flag: 'tj', name: 'Tajikistan' },
  { code: '690', flag: 'tk', name: 'Tokelau' },
  { code: '670', flag: 'tl', name: 'Timor-leste' },
  { code: '993', flag: 'tm', name: 'Turkmenistan' },
  { code: '216', flag: 'tn', name: 'Tunisia' },
  { code: '676', flag: 'to', name: 'Tonga' },
  { code: '90', flag: 'tr', name: 'Turkey' },
  { code: '1868', flag: 'tt', name: 'Trinidad and tobago' },
  { code: '688', flag: 'tv', name: 'Tuvalu' },
  { code: '886', flag: 'tw', name: 'Taiwan, province of china' },
  { code: '255', flag: 'tz', name: 'Tanzania' },
  { code: '380', flag: 'ua', name: 'Ukraine' },
  { code: '256', flag: 'ug', name: 'Uganda' },
  { code: '1', flag: 'us', name: 'United states' },
  { code: '598', flag: 'uy', name: 'Uruguay' },
  { code: '998', flag: 'uz', name: 'Uzbekistan' },
  { code: '39', flag: 'va', name: 'Holy see (vatican city state)' },
  { code: '1784', flag: 'vc', name: 'Saint vincent and the grenadines' },
  { code: '58', flag: 've', name: 'Venezuela' },
  { code: '1284', flag: 'vg', name: 'Virgin islands, british' },
  { code: '1340', flag: 'vi', name: 'Virgin islands, u.s.' },
  { code: '84', flag: 'vn', name: 'Viet nam' },
  { code: '678', flag: 'vu', name: 'Vanuatu' },
  { code: '681', flag: 'wf', name: 'Wallis and futuna' },
  { code: '685', flag: 'ws', name: 'Samoa' },
  { code: '967', flag: 'ye', name: 'Yemen' },
  { code: '262', flag: 'yt', name: 'Mayotte' },
  { code: '27', flag: 'za', name: 'South africa' },
  { code: '260', flag: 'zm', name: 'Zambia' },
  ],

};
