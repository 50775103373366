import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyResponseComponent } from './components/survey-response/survey-response.component';
import { SurveyFormComponent } from './components/survey-form/survey-form.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'survey-form', component: SurveyFormComponent },
  { path: 'survey-response', component: SurveyResponseComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
