import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../providers/survey.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as Survey from 'survey-angular';

@Component({
	selector: 'app-survey-form',
	templateUrl: './survey-form.component.html',
	providers: [SurveyService]
})
export class SurveyFormComponent implements OnInit {

	campaignId: any;
  formId: any;
	consumerId: any;
	businessId: any;
  isCampaignSurvey: boolean;
  err: string;
  serviceName: any;

	constructor(
		private survey: SurveyService,
		private route: ActivatedRoute,
    	private router: Router
		) { }

	ngOnInit() {
		this.campaignId = this.route.snapshot.queryParams['campaignId']
    this.formId = this.route.snapshot.queryParams['formId']

    if(this.campaignId == null)
      this.isCampaignSurvey = false
    else
      this.isCampaignSurvey = true

		this.consumerId = this.route.snapshot.queryParams['consumerId']
    this.serviceName = this.route.snapshot.queryParams['serviceName']

    if(this.isCampaignSurvey)
		  this.getCampaignDetails(this.campaignId)
    else
      this.getFormDetails(this.formId)
	}
  getFormDetails(formId) {
    this.survey.getFormDetailsById(this.formId).subscribe(
      data => {
        console.log(JSON.stringify(data))
        this.businessId = data.businessId
        var surveyJSON = data.questionnaire.content
        const surveyModel = new Survey.Model(surveyJSON);
        surveyModel.onComplete
        .add(result =>{
          this.sendRequest('', this.serviceName, 'General', JSON.stringify(result.data), formId)
          });
        Survey.SurveyNG.render('surveyElement', { model: surveyModel });
      },
      error => {
        this.survey.showToast('error', '', error);
      }
    );
  }
	getCampaignDetails(campaignId){

		this.survey
		.getFeedbackCampaignById(campaignId)
		.subscribe(
			data => {
				console.log(data)
				this.businessId = data.businessId
				var surveyJSON = data.setting.question.content
				const surveyModel = new Survey.Model(surveyJSON);
				surveyModel.onComplete
				.add(result =>
					this.sendRequest('', this.serviceName, 'Feedback', JSON.stringify(result.data), data.campaignId )
					);
				Survey.SurveyNG.render('surveyElement', { model: surveyModel });
			},
			error => {
				this.survey.showToast('error', '', error);
			}
			);
	}


  /**
   *
   * @param note
   * @param serviceName
   * @param serviceType
   * @desc : On general resquest subbmition API submitConsumerRequest hit to submit resquest.
   */
  sendRequest(note: any, serviceName: any, serviceType: any, responseContent:any, surveyId: any) {
  	console.log(this.consumerId, this.businessId)
    if (this.consumerId) {
      const notes = note.note;
      this.survey
        .submitConsumerRequest(
          this.businessId,
          false,
          'null',
          this.consumerId,
          'Search',
          notes,
          serviceName,
          serviceType,
          (status = 'New'),
          this.isCampaignSurvey ? surveyId : null,
          this.isCampaignSurvey ? responseContent : null,
          !this.isCampaignSurvey ? surveyId : null,
          !this.isCampaignSurvey ? responseContent : null
        )
        .subscribe(
          data => {
            if (data.status == 201 || data.status == 200) {
              console.log('data:::', data.json());
              const id = data.json().id;
              window.close()
            } else {
              this.err = 'Invalid request!';
            }
          },
          error => {
            if (
              error ===
              'Looks like your account was disabled. Please register your number again to Submit new request.'
            ) {
              this.err = error.errorDetails[0]['value'];
              const service = {
                note: notes,
                serviceName: serviceName,
                serviceType: serviceType
              };
              // this.sharedservice.saveData(this.err);
            } else {
              	this.survey.showToast('error', '', error);
            }
          }
        );
    } else {
      this.survey.showToast('error', '', 'You need to login first before placing any request.');
      // const service = {
      //   note: note,
      //   serviceName: serviceName,
      //   serviceType: serviceType
      // };
      // this.sharedservice.saveData(
      //   'You need to login first before placing any request.'
      // );
    }
  }

}
