import { Component } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { Http, Request, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SurveyService {

    url: string;

    constructor(
        private http: Http, 
        private toastr: ToastrService) {
        this.url = environment.api_url;

    }

    private handleError(error: any) {
        let errorMessage: string;
        const tempObj = error.json();
        errorMessage = tempObj.errorDetails ? tempObj.errorDetails[0].value : 'Something went wrong';
        return Observable.throw(errorMessage);
    }

    /**
    * @param : headers
    * @desc : Sets Headers of Http request, access allow permission of cross domain
    */
    createAuthorizationHeader(headers: Headers) {
        headers.append('Accept', 'application/json');
        headers.append('Accept', 'application/pdf');
        headers.append('Content-Type', 'application/json');
    }

    /**
    * @param : requestDetailObj
    * @desc : Hits consumer/request/requestdetails/ api to get the detail of a request id
    * @return : list of request details.
    */
    getRequestsDetailsById(requestId: any) {

        const headers = new Headers();
        this.createAuthorizationHeader(headers);
        const options = new RequestOptions({headers: headers});
        const getUrl = this.url + 'consumer/request/requestdetails/' + requestId;

        return this.http.get(getUrl, options)
                            .map((res: Response) => res.json())
                            .catch(this.handleError);
    }

    /**
    * @param : campaignObj
    * @desc : Hits feedback/campaign/ api to get the campaign details by id
    * @return : list of campaign details.
    */
    getFeedbackCampaignById(campaignId){

        const headers = new Headers();
        this.createAuthorizationHeader(headers);
        const options = new RequestOptions({headers: headers});
        const Url = environment.api_url + '/feedback/campaign/' + campaignId + '?isActive=true&isDeleted=false';

        return this.http.get(Url, options)
                            .map((res: Response) => res.json())
                            .catch(this.handleError);  
    }


    showToast(type,title,message) {
 
        if(type == 'warning')
            this.toastr.warning(title, message);
        else if(type == 'success')
            this.toastr.success(title, message);
        else if(type == 'error')
            this.toastr.error(title, message);
        else if(type == 'info')
            this.toastr.info(title, message);
    }
    /**
    * @param : businessId,DeviceId,consumerId,locationName,notes,serviceName,serviceType & status
    * @desc : Hits get API request to submit the general request.
    * @return : success true or false with the request Id generated.
    */
    submitConsumerRequest(
        businessId,
        isOwnerRequest,
        DeviceId,
        consumerId,
        locationName,
        notes,
        serviceName,
        serviceType,
        status,
        campaignId,
        campaignResponseContent,
        formId,
        formResponse
        ): Observable <any> {

        const headers = new Headers();
        this.createAuthorizationHeader(headers);
        const options = new RequestOptions({headers: headers});
        const Url = this.url + 'consumer/request/';
        let clientSource = 'Consumer_Web';
        // if (this.localstorage.get('fromExternal')) {
        //     clientSource = 'Business_Web';
        // }
        const body = {
                    'busId' : businessId,
                    'consumerDeviceId' : DeviceId,
                    'isOwnerRequest' : isOwnerRequest,
                    'consumerId' : consumerId,
                    'locationName' : locationName,
                    'notes' : notes,
                    'serviceName' : serviceName,
                    'serviceType' : serviceType,
                    'clientSource': clientSource,
                    'status' : status,
                    'campaignId' : campaignId,
                    'campaignResponseContent' : campaignResponseContent,
                    'formId' : formId,
                    'formResponse' : formResponse
                };

                return this.http.post(Url, body, options)
                                    .map((res: Response) => res)
                                    .catch(this.handleError);
    }

    //get Form details by ID
    getFormDetailsById(formId){

        const headers = new Headers();
        this.createAuthorizationHeader(headers);
        const options = new RequestOptions({headers: headers});
        const Url = environment.api_url + '/forms/' + formId;

        return this.http.get(Url, options)
                            .map((res: Response) => res.json())
                            .catch(this.handleError);  
    }

}
